@font-face {
    font-family: 'Geist Variable';
    src: url('../fonts/GeistVariableVF.woff2') format('woff2');
    font-weight: normal;
}

@font-face {
    font-family: 'Geist Mono Variable';
    src: url('../fonts/GeistMonoVariableVF.woff2') format('woff2');
    font-weight: normal;
}

:root {
    --bg: #000;
    --fg: #ededed;
}

body {
    margin: 3em;

    background-color: var(--bg);
    color: var(--fg);

    font-size: 14px;
    font-family: 'Geist Mono Variable', monospace;
    font-variation-settings: 'wght' 300;
}

.App {
    max-width: 800px;
}

h1 {
    font-variation-settings: 'wght' 600;
    font-family: 'Geist Variable', sans-serif;

    margin-block-end: 0;
}

.subtitle {
    margin-block-start: 0.5em;
}

input {
    background: none;
    color: var(--fg);
    border: 0.5px solid var(--fg);
    border-radius: 0;

    max-width: 100px;
    
    margin-block-start: 3em;
    margin-block-end: 1.5em;
}

input:not(:last-of-type) {
    margin-inline-end: 0.5em;
}

.loading {
    opacity: 75%;
    font-variation-settings: 'wght' 200;
}

.coin {
    text-transform: lowercase;
}

.coin:not(:last-of-type) {
    margin-block: 2.25em;
}

.coin:first-of-type {
    margin-block-start: 0em;
}

h2 {
    font-size: 1.25em;
}

.symbol {
    font-size: 0.8em;
    opacity: 75%;
    font-variation-settings: 'wght' 200;
}